<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">

  <Body>
    <div>
      <div id="body-content"></div>
      <Header />
      <!-- <div class="catagory-dropdown" v-if="false && categoryImagesWithLabels.length">
      <div class="catagory-items">
        <div class="all-items">
          <div class="catagory-item-sub" v-for="cImg in categoryImagesWithLabels" :key="cImg.slug">
            <NuxtLinkLocale :to="cImg.url" class="catagory-items-sub-info">
              <img :src="imgUrl(cImg.image.url)" class="img-info-catagory" />
              <p class="catagory-text">
                {{ getCategoryLabel(cImg.label[0]) }}
              </p>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div> -->

      <div class="products-dropdown printPdf" v-if="productsMenu.length">
        <div class="catagory-items ">
          <div class="cat-tree ">
            <CategoryCategoryTree />
          </div>
          <div class="all-items all-items-deals green-nav-height" >
            
            <div class="catagory-items-sub-info catagory-item-sub" v-for="pm in productsMenu" :key="pm.key">
              <p v-if="!privateProductsMenus[pm.key] || (privateProductsMenus[pm.key] && isAuthenticated)"
                class="catagory-text" @click="addFilter(pm.query)">
                <img :src="image(pm.img, { height: 25 })" style="width: 25px;" alt="icon"/>
                {{ $t(`menu.${pm.key}`) }} {{ "&nbsp; " }}
              </p>
            </div>
          </div>
        </div>

      </div>
      <WidgetsBreadcrumbs />
      <NuxtPage />
      <ClientOnly>
        <div class="tap-top top-cls printPdf" v-scroll-to="'#body-content'">
          <div>
            <i class="fa fa-angle-double-up"></i>
          </div>
        </div>
      </ClientOnly>

      <LazyFooter> </LazyFooter>
    </div>
  </Body>

  </Html>
</template>

<script setup>

import { useCategoryStore } from "~/store/CategoryStore";
import { useUtilityStore } from "~/store/UtililityStore";
import { useCmsStore } from "~/store/cms/cmsStore";
import { productsMenu, privateProductsMenus } from "~/composables/data";
import { useFilterStore } from "~/store/FilterStore";
import { useUserStore } from "~/store/UserStore";
import { useI18n } from "vue-i18n";
import CategoryCategoryTree from '../components/Category/TreeNavigation.vue';
import { useOrderStore } from "~/store/OrderStore";
// import { useOrderStore } from "~/store/OrderStore";

const { t } = useI18n();
const head = useLocaleHead({
  addDirAttribute: true,      // Adds dir
  addSeoAttributes: true,     // Adds lang
})
useHead({
  title: "CLD Distribution - Leading European Distributor and Wholesaler in Entertainment and Pop-Culture ",
  meta: [{ name: "description", content: t('cld_desc1') }],
  link: [...(head.value.link || [])],
});

const htmlAttrs = computed(() => head.value.htmlAttrs)

const image = useImage()
const cmsStore = useCmsStore();
const categoryStore = useCategoryStore();
const utilityStore = useUtilityStore();
const userStore = useUserStore();
const filterStore = useFilterStore();
const orderStore = useOrderStore();

// const parentCategories = computed(() => categoryStore.parentCategories);
const imgUrl = (url) => cmsStore.getImageUrl(url);
const categoryImages = computed(() => cmsStore.categoryImages);
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const parentCategoriesObj = computed(() => categoryStore.parentCategoriesObj);

// const categoryImagesWithLabels = computed(() => {
//   const result = [];
//   for (let i = 0; i < categoryImages.value.length; i++) {
//     const slug = categoryImages.value[i].slug;
//     if (parentCategoriesObj.value && parentCategoriesObj.value[slug]) {
//       const label =
//         parentCategoriesObj.value[slug].parent_top_hits.hits.hits[0]._source
//           .Values.category_tree[0].Data;
//       result.push({
//         label,
//         ...categoryImages.value[i],
//       });
//     }
//   }

//   return result;
// });
const isAuthenticated = computed(() => userStore.isAuthenticated);


const addFilter = (v) => {
  const { attribute, sort } = v
  filterStore.setSelectedAttributeFilters(attribute)
  if (sort && sort?.length) {
    filterStore.setSelectedSort(sort)
  }
  else {
    filterStore.setSelectedSort([])
  }
}

// const getCategoryLabel = computed(() => {
//   return (labels) => labels.Labels[selectedLanguage.value] || labels.Labels['en_GB']

// })
onBeforeMount(() => {
  setTimeout(() => {
    categoryStore.getParentCategories();
    cmsStore.getHomePage();
    cmsStore.getLicense();
    cmsStore.getCoverSliders();
    cmsStore.getCmsCategory();
  }, 0);
});

onMounted(() => {
  setTimeout(() => {
    orderStore.getAccountingSituationLocalStorage()
  }, 0);
})


</script>
<style>
.catagory-brands,
.catagory-items {
  box-shadow: 2px 3px 20px 2px rgba(0, 0, 0, 0.1);
  margin: 10px 0px;
}

.catagory-dropdown {
  /* margin: 70px 0 0 0; */
  /* max-width: 1600px; */
  min-width: auto;
}

.all-items,
.all-brands {
  display: flex;
  justify-content: center;
  align-items: center;

}

.catagory-items-sub-info:hover,
.catagory-brands-sub-info:hover {
  border-bottom: 2px solid var(--theme-deafult);
}

.catagory-items-sub-info {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 2px solid transparent;
  gap: 5px;
}

.img-info-catagory {
  height: 20px;
  width: 20px;
}

.spinner {
  /* margin: 100px auto; */
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner>div {
  background-color: #346715;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

.catagory-text {
  margin-top: 10px;
  cursor: pointer;
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
